import {
  COMPANY_ADVANTAGE,
  COMPANY_CANDIDATE_SPECIALIZATION,
  COMPANY_CANDIDATE_TRAINING_EXP,
  COMPANY_FILE,
  COMPANY_JOB_OFFER,
  COMPANY_LANGUAGE,
  COMPANY_LEVEL,
  COMPANY_LOOKING_FOR,
  COMPANY_LOOKING_STAFF,
  COMPANY_STUDY,
  COMPANY_UNIVERSITY
} from "../../constants/action-types/CompanySignup/companySignup4.actionTypes";

/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for passing reducer function of company signup page 4
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
const initialState = {
  companyLookingFor: "",
  companyLanguage: "",
  companyLevel: "",
  companyJobOffer: [],
  companyStaff: [],
  companyStudy: [],
  companyUniversity: "",
  companyAdvantage: "",
  companyTrainingExp: "",
  companyCandidateSpecialization: [],
  companyFile: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_LOOKING_FOR:
      return {
        ...state,
        companyLookingFor: payload
      };
    case COMPANY_LANGUAGE:
      return {
        ...state,
        companyLanguage: payload
      };
    case COMPANY_LEVEL:
      return {
        ...state,
        companyLevel: payload
      };
    case COMPANY_JOB_OFFER:
      return {
        ...state,
        companyJobOffer: payload
      };
    case COMPANY_LOOKING_STAFF:
      return {
        ...state,
        companyStaff: payload
      };
    case COMPANY_STUDY:
      return {
        ...state,
        companyStudy: payload
      };
    case COMPANY_UNIVERSITY:
      return {
        ...state,
        companyUniversity: payload
      };
    case COMPANY_ADVANTAGE:
      return {
        ...state,
        companyAdvantage: payload
      };
    case COMPANY_CANDIDATE_TRAINING_EXP:
      return {
        ...state,
        companyTrainingExp: payload
      };
    case COMPANY_CANDIDATE_SPECIALIZATION:
      return {
        ...state,
        companyCandidateSpecialization: payload
      };
    case COMPANY_FILE:
      return {
        ...state,
        companyFile: payload
      };
    default:
      return state;
  }
};
