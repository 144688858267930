/**
 * @author: Ashay Tiwari
 * @date: 5 Aug 2021
 * @description: File for passing reducer function of company dashboard actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  COMPANY_FOLLOWING_LIST,
  COMPANY_LIST_OF_APPLICANTS,
  COMPANY_STUDENT_LIST
} from "../constants/action-types/companyDashboard.actionTypes";

const initialState = {
  studentList: [],
  companyFollowingList: [],
  listOfApplicants: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_STUDENT_LIST:
      return {
        ...state,
        studentList: payload
      };
    case COMPANY_FOLLOWING_LIST:
      return {
        ...state,
        companyFollowingList: payload
      };
    case COMPANY_LIST_OF_APPLICANTS:
      return {
        ...state,
        listOfApplicants: payload
      };
    default:
      return state;
  }
};
