/**
 * @author: Ashay Tiwari
 * @date: 17 Aug 2021
 * @description: File for admin Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const GET_HIPOZ_STUDENT_LIST = "GET_HIPOZ_STUDENT_LIST";
export const GET_TALENTPOOL_STUDENT_LIST = "GET_TALENTPOOL_STUDENT_LIST";
export const GET_STUDENT_JOB_APPLY_LIST = "GET_STUDENT_JOB_APPLY_LIST";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const GET_COMPANY_JOB_LIST = "GET_COMPANY_JOB_LIST";
export const GET_SUBSCRIPTIONS_PLANS = "GET_SUBSCRIPTIONS_PLANS";
export const GET_SUBSCRIPTION_INFO = "GET_SUBSCRIPTION_INFO";
export const GET_SUBSCRIBERS_LIST = "GET_SUBSCRIBERS_LIST";
export const GET_PARTNER_LIST = "GET_PARTNER_LIST";
export const GET_PARTNER_EVENTS_LIST = "GET_PARTNER_EVENTS_LIST";
export const GET_PARTNER_BENEFITS_LIST = "GET_PARTNER_BENEFITS_LIST";
export const GET_STUDENT_SIGNUP_CONTENT = "GET_STUDENT_SIGNUP_CONTENT";
export const GET_COMPANY_SIGNUP_CONTENT = "GET_COMPANY_SIGNUP_CONTENT";
export const GET_STUDENT_BANNER_LIST = "GET_STUDENT_BANNER_LIST";
export const GET_COMPANY_BANNER_LIST = "GET_COMPANY_BANNER_LIST";
export const GET_BENEFIT_BANNER_LIST = "GET_BENEFIT_BANNER_LIST";
export const GET_EVENT_BANNER_LIST = "GET_EVENT_BANNER_LIST";
export const GET_BENEFIT_OFFER_LIST = "GET_BENEFIT_OFFER_LIST";
export const GET_EVENT_OFFER_LIST = "GET_EVENT_OFFER_LIST";
export const GET_NEWS_FEED_LIST = "GET_NEWS_FEED_LIST";
export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE";
export const GET_STUDENT_SUBSCRIBERS_LIST = "GET_STUDENT_SUBSCRIBERS_LIST";
export const GET_COMPANY_SUBSCRIBERS_LIST = "GET_COMPANY_SUBSCRIBERS_LIST";
export const GET_PARTNER_SUBSCRIBERS_LIST = "GET_PARTNER_SUBSCRIBERS_LIST";
export const GET_ALL_SUBSCRIBERS_LIST = "GET_ALL_SUBSCRIBERS_LIST";
export const GET_ADMIN_DASHBOARD_COUNT = "GET_ADMIN_DASHBOARD_COUNT";
export const GET_TERMS_CONTENT = "GET_TERMS_CONTENT";
