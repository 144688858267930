import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import Slide from "@material-ui/core/Slide";
import {
  SnackbarAutohideDuration,
  SnackbarDuplicate,
  SnackbarHorizontalPosition,
  SnackbarVerticalPosition
} from "./middlewares/configFileAccessor";
import { IconButton, makeStyles } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import globalStyles from "./App.css";
import { QueryClientProvider, QueryClient } from "react-query";
import Loader from "./component/UI/Loader/Loader";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// const UserDashboard = React.lazy(() =>
//   import("./container/UserDashboard/UserDashboardRoutes/UserDashboardRoutes")
// );
// const AdminLogin = React.lazy(() =>
//   import("./container/AdminDashboard/AdminLogin/AdminLogin")
// );
// const ForgotPassword = React.lazy(() =>
//   import("./container/AdminDashboard/AdminLogin/ForgotPassword")
// );
// const ResetPassword = React.lazy(() =>
//   import("./container/AdminDashboard/AdminLogin/ResetPassword")
// );
// const LandingPage = React.lazy(() =>
//   import("./container/LandingPages/LandingPage/LandingPage")
// );
// const SignupPage = React.lazy(() =>
//   import("./container/LandingPages/StudentSignup/StudentSignup")
// );
// const CompanySignup = React.lazy(() =>
//   import("./container/LandingPages/CompanySignup/CompanySignup")
// );
// const StudentLogin = React.lazy(() =>
//   import("./container/LandingPages/StudentLogin/StudentLogin")
// );
// const ForgotPassword = React.lazy(() =>
//   import("./container/LandingPages/StudentLogin/ForgotPassword")
// );
// const ResetPassword = React.lazy(() =>
//   import("./container/LandingPages/StudentLogin/ResetPassword")
// );
// const CompanyDashboard = React.lazy(() =>
//   import("./container/CompanyDashboard/CompanyDashboard")
// );
// const StudentDashboard = React.lazy(() =>
//   import("./container/StudentDashboard/Home/StudentDashboard")
// );
// const StudentProfile = React.lazy(() =>
//   import("./container/StudentDashboard/Profile/Profile")
// );
// const StudentMessaging = React.lazy(() =>
//   import("./container/StudentDashboard/Messaging/Messaging")
// );
// const CompanyJobs = React.lazy(() =>
//   import("./container/CompanyDashboard/Jobs/Jobs")
// );
// const CompanyExistingJobs = React.lazy(() =>
//   import("./container/CompanyDashboard/ExistingJobs/ExistingJobs")
// );
// const ListOfApplicants = React.lazy(() =>
//   import("./container/CompanyDashboard/ListOfApplicants/ListOfApplicants")
// );
// const StudentAppliedJobs = React.lazy(() =>
//   import("./container/StudentDashboard/AppliedJobs/AppliedJobs")
// );
// const StudentSearchResult = React.lazy(() =>
//   import("./container/StudentDashboard/SearchResult/SearchResult")
// );
// const CompanyProfile = React.lazy(() =>
//   import("./container/CompanyDashboard/Profile/Profile")
// );
// const StudentBenefitWorld = React.lazy(() =>
//   import("./container/StudentDashboard/BenefitWorld/BenefitWorld")
// );
// const StudentEventWorld = React.lazy(() =>
//   import("./container/StudentDashboard/EventWorld/EventWorld")
// );
// const PartnerHome = React.lazy(() =>
//   import("./container/PartnerDashboard/Home/Home")
// );
// const PartnerAddCoupon = React.lazy(() =>
//   import("./container/PartnerDashboard/AddCoupon/AddCoupon")
// );
// const PartnerAddEvent = React.lazy(() =>
//   import("./container/PartnerDashboard/AddEvent/AddEvent")
// );
// const StudentCompanyList = React.lazy(() =>
//   import("./container/StudentDashboard/CompanyList/CompanyList")
// );
// const StudentCompanyProfile = React.lazy(() =>
//   import("./container/StudentDashboard/CompanyProfile/CompanyProfile")
// );
// const CompanyBenefitWorld = React.lazy(() =>
//   import("./container/CompanyDashboard/BenefitWorld/BenefitWorld")
// );
// const CompanyEventWorld = React.lazy(() =>
//   import("./container/CompanyDashboard/EventWorld/EventWorld")
// );
// const CompanyStudentList = React.lazy(() =>
//   import("./container/CompanyDashboard/StudentList/StudentList")
// );
// const CompanyStudentProfile = React.lazy(() =>
//   import("./container/CompanyDashboard/StudentProfile/StudentProfile")
// );
// const StudentJobsList = React.lazy(() =>
//   import("./container/StudentDashboard/JobsList/JobsList")
// );
// const CompanyEditJobPost = React.lazy(() =>
//   import("./container/CompanyDashboard/Jobs/Jobs")
// );
const AdminLogin = React.lazy(() =>
  import("./container/HipozAdminDashboard/LandingPages/Login")
);
const AdminDashboardWrapper = React.lazy(() =>
  import("./container/HipozAdminDashboard/DashboardWrapper/DashboardWrapper")
);

const useStyles = makeStyles({
  error: { backgroundColor: "#ff726f" }
});

// React Query's client query
const queryClient = new QueryClient();

function App() {
  const classes = useStyles();
  const notistackRef = React.createRef();

  /**
   * function to close snackbar notifications
   * @param {*} key
   * @returns close snackbar action
   */
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  }; // end of onClickDismiss

  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: SnackbarVerticalPosition,
        horizontal: SnackbarHorizontalPosition
      }}
      preventDuplicate={SnackbarDuplicate}
      autoHideDuration={SnackbarAutohideDuration}
      TransitionComponent={Slide}
      ref={notistackRef}
      classes={{
        variantError: classes.error
      }}
      action={(key) => (
        <IconButton
          onClick={onClickDismiss(key)}
          className={globalStyles.iconButton}
        >
          <CloseOutlined className={globalStyles.close} />{" "}
        </IconButton>
      )}
    >
      <QueryClientProvider client={queryClient}>
        <div className="app">
          <Router>
            <Suspense
              fallback={
                <div className={globalStyles.loaderWrapper}>
                  <Loader />
                </div>
              }
            >
              <Switch>
                {/* Common routes starts here */}
                <Route
                  path={"/"}
                  exact
                  render={() => <Redirect to={"/login"} />}
                />
                <Route path={"/login"} component={AdminLogin} />
                <Route path="/dashboard" component={AdminDashboardWrapper} />
                {/* <Route path="/signin" exact component={StudentLogin} />
                <Route path="/forgotPassword" component={ForgotPassword} />
                <Route path="/resetPassword/:id" component={ResetPassword} /> */}
                {/* Common routes ends here */}

                {/* Routes for company starts here */}
                {/* <Route path="/company/signup" exact component={CompanySignup} />
                <Route
                  path="/company/jobs"
                  component={() => <CompanyJobs callingFor={"add"} />}
                />
                <Route
                  path="/company/existingJobs"
                  component={CompanyExistingJobs}
                />
                <Route
                  path="/company/listOfApplicants"
                  component={ListOfApplicants}
                />
                <Route path="/company/dashboard" component={CompanyDashboard} />
                <Route path="/company/profile" component={CompanyProfile} />
                <Route
                  path="/company/benefitworld"
                  component={CompanyBenefitWorld}
                />
                <Route
                  path={"/company/eventworld"}
                  component={CompanyEventWorld}
                />
                <Route
                  path={"/company/students"}
                  component={CompanyStudentList}
                />
                <Route
                  path={"/company/studentProfile/:id"}
                  component={CompanyStudentProfile}
                />
                <Route
                  path={"/company/editPost/:id"}
                  component={() => <CompanyEditJobPost callingFor={"edit"} />}
                />
                <Route
                  path={"/company/messaging"}
                  component={() => <StudentMessaging callingFrom={"company"} />}
                />
                <Route
                  path={"/company/searchResult"}
                  component={() => (
                    <StudentSearchResult callingFrom={"Company"} />
                  )}
                /> */}
                {/* Routes for company ends here */}

                {/* Routes for student starts here */}
                {/* <Route path="/student/signup" exact component={SignupPage} />
                <Route path="/student/dashboard" component={StudentDashboard} />
                <Route path="/student/profile" component={StudentProfile} />
                <Route
                  path="/student/messaging"
                  component={() => <StudentMessaging callingFrom={"student"} />}
                />
                <Route
                  path="/student/appliedJobs"
                  component={StudentAppliedJobs}
                />
                <Route
                  path="/student/searchResult"
                  component={() => (
                    <StudentSearchResult callingFrom={"Student"} />
                  )}
                />
                <Route
                  path="/student/benefitworld"
                  component={StudentBenefitWorld}
                />
                <Route
                  path="/student/eventworld"
                  component={StudentEventWorld}
                />
                <Route path="/student/company" component={StudentCompanyList} />
                <Route
                  path="/student/companyProfile/:id"
                  component={StudentCompanyProfile}
                />
                <Route path="/student/jobs" component={StudentJobsList} /> */}

                {/* Routes for student ends here */}

                {/* Routes for Partner starts here */}
                {/* <Route path="/partner/profile" component={PartnerHome} />
                <Route
                  path="/partner/addProduct"
                  component={PartnerAddCoupon}
                />
                <Route path="/partner/addEvent" component={PartnerAddEvent} />
                <Route
                  path="/partner/searchResult"
                  component={() => (
                    <StudentSearchResult callingFrom={"Partner"} />
                  )}
                /> */}
                {/* Routes for Partner ends here */}

                <Route component={() => <h1>Not Found</h1>} />
              </Switch>
            </Suspense>
          </Router>
        </div>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;
