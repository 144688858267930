/**
 * @author: Ashay Tiwari
 * @date: 6 Apr 2021
 * @description: File for combining all reducers and exporting them.
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import { combineReducers } from "redux";
import enums from "./enum.reducer";
import studentSignupPage1 from "./StudentSignup/signup1.reducer";
import studentSignupPage2 from "./StudentSignup/signup2.reducer";
import studentSignupPage3 from "./StudentSignup/signup3.reducer";
import studentSignupPage4 from "./StudentSignup/signup4.reducer";
import studentSignupPage5 from "./StudentSignup/signup5.reducer";
import companySignupPage1 from "./CompanySignup/companySignup1.reducer";
import companySignupPage2 from "./CompanySignup/companySignup2.reducer";
import companySignupPage3 from "./CompanySignup/companySignup3.reducer";
import companySignupPage4 from "./CompanySignup/companySignup4.reducer";
import studentProfile from "./studentProfile.reducer";
import companyProfile from "./companyProfile.reducer";
import studentDashboard from "./studentDashboard.reducer";
import partnerWorld from "./partnerWorld.reducer";
import common from "./common.reducer";
import companyDashboard from "./companyDashboard.reducer";
import chat from "./chat.reducer";
import admin from "./admin.reducer";
import adminGraphs from "./adminGraphs.reducer";

export default combineReducers({
  enums,
  studentSignupPage1,
  studentSignupPage2,
  studentSignupPage3,
  studentSignupPage4,
  studentSignupPage5,
  companySignupPage1,
  companySignupPage2,
  companySignupPage3,
  companySignupPage4,
  studentProfile,
  companyProfile,
  studentDashboard,
  partnerWorld,
  common,
  companyDashboard,
  chat,
  admin,
  adminGraphs
});
