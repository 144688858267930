/**
 * @author: Ashay Tiwari
 * @date: 11 July 2021
 * @description: File for passing reducer function of student signup page 4
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
import {
  STUDENT_COMPANY_LOCATION,
  STUDENT_CV_FILE,
  STUDENT_INDUSTRY_FIELD,
  STUDENT_INTERESTED,
  STUDENT_JOB_TYPE,
  STUDENT_LANGUAGES,
  STUDENT_LEVEL,
  STUDENT_SALARY_EXPECTATION,
  STUDENT_SPECIALIZATION,
  STUDENT_WORK_ABROAD
} from "../../constants/action-types/StudentSignup/signup4.actionTypes";

const initialState = {
  studentCvFile: "",
  studentJobType: "",
  studentLanguage: "",
  studentLevel: "",
  studentSpecialization: [],
  studentInterestedWork: [],
  studentIndustryField: [],
  studentCompanyLocation: [],
  studentWorkAbroad: "134",
  studentSalaryExpectation: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STUDENT_CV_FILE:
      return {
        ...state,
        studentCvFile: payload
      };
    case STUDENT_JOB_TYPE:
      return {
        ...state,
        studentJobType: payload
      };
    case STUDENT_LANGUAGES:
      return {
        ...state,
        studentLanguage: payload
      };
    case STUDENT_LEVEL:
      return {
        ...state,
        studentLevel: payload
      };
    case STUDENT_SPECIALIZATION:
      return {
        ...state,
        studentSpecialization: payload
      };

    case STUDENT_INTERESTED:
      return {
        ...state,
        studentInterestedWork: payload
      };
    case STUDENT_INDUSTRY_FIELD:
      return {
        ...state,
        studentIndustryField: payload
      };
    case STUDENT_COMPANY_LOCATION:
      return {
        ...state,
        studentCompanyLocation: payload
      };
    case STUDENT_WORK_ABROAD:
      return {
        ...state,
        studentWorkAbroad: payload
      };
    case STUDENT_SALARY_EXPECTATION:
      return {
        ...state,
        studentSalaryExpectation: payload
      };
    default:
      return state;
  }
};
