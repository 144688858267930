/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for passing reducer function of company signup page 3
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  COMPANY_BRANCHES,
  COMPANY_EMPLOYEES,
  COMPANY_LOCATION,
  COMPANY_SPECIALIZATION
} from "../../constants/action-types/CompanySignup/companySignup3.actionTypes";

const initialState = {
  companyEmployees: "",
  companySpecialization: [],
  companyBranches: "",
  companyLocation: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_EMPLOYEES:
      return {
        ...state,
        companyEmployees: payload
      };
    case COMPANY_SPECIALIZATION:
      return {
        ...state,
        companySpecialization: payload
      };
    case COMPANY_BRANCHES:
      return {
        ...state,
        companyBranches: payload
      };
    case COMPANY_LOCATION:
      return {
        ...state,
        companyLocation: payload
      };
    default:
      return state;
  }
};
