/**
 * @author: Ashay Tiwari
 * @date: 9 Apr 2021
 * @description: File for middleware between config file and components
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import configData from "../config/config.json";

//Global constants for Snackbar Configuration
export const SnackbarVerticalPosition = configData.SNACKBAR.VERTICAL_POSITION;
export const SnackbarHorizontalPosition =
  configData.SNACKBAR.HORIZONTAL_POSITION;
export const SnackbarDuplicate = configData.SNACKBAR.PREVENT_DUPLICATE;
export const SnackbarAutohideDuration =
  configData.SNACKBAR.AUTOHIDE_DURATION_IN_MILI_SECOND;

//Global constants for Session encryption secret key
export const SessionEncryptionSecretKey =
  configData.SESSION_STORAGE_ENCRYPTION_SECRET_KEY;
