/**
 * @author: Ashay Tiwari
 * @date: 17 Aug 2021
 * @description: File for passing reducer function of admin actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  GET_ADMIN_DASHBOARD_COUNT,
  GET_ADMIN_PROFILE,
  GET_ALL_SUBSCRIBERS_LIST,
  GET_BENEFIT_BANNER_LIST,
  GET_BENEFIT_OFFER_LIST,
  GET_COMPANY_BANNER_LIST,
  GET_COMPANY_JOB_LIST,
  GET_COMPANY_LIST,
  GET_COMPANY_SIGNUP_CONTENT,
  GET_COMPANY_SUBSCRIBERS_LIST,
  GET_EVENT_BANNER_LIST,
  GET_EVENT_OFFER_LIST,
  GET_HIPOZ_STUDENT_LIST,
  GET_NEWS_FEED_LIST,
  GET_PARTNER_BENEFITS_LIST,
  GET_PARTNER_EVENTS_LIST,
  GET_PARTNER_LIST,
  GET_PARTNER_SUBSCRIBERS_LIST,
  GET_STUDENT_BANNER_LIST,
  GET_STUDENT_JOB_APPLY_LIST,
  GET_STUDENT_SIGNUP_CONTENT,
  GET_STUDENT_SUBSCRIBERS_LIST,
  GET_SUBSCRIBERS_LIST,
  GET_SUBSCRIPTIONS_PLANS,
  GET_SUBSCRIPTION_INFO,
  GET_TALENTPOOL_STUDENT_LIST,
  GET_TERMS_CONTENT
} from "../constants/action-types/admin.actionTypes";

const initialState = {
  hipozStudentList: [],
  talentpoolStudentList: [],
  studentJobApplyList: [],
  companyList: [],
  companyJobList: [],
  subscriptionsPlans: [],
  subscriptionInfo: [],
  subscribersList: [],
  partnerList: [],
  partnerEventsList: [],
  partnerBenefitsList: [],
  studentSignupContent: [],
  companySignupContent: [],
  studentBannerList: [],
  companyBannerList: [],
  benefitBannerList: [],
  eventBannerList: [],
  benefitOfferList: [],
  eventOfferList: [],
  newsFeedList: [],
  adminProfile: [],
  studentSubscribersList: [],
  companySubscribersList: [],
  partnerSubscribersList: [],
  allSubscribersList: [],
  adminDashboardCount: [],
  terms: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HIPOZ_STUDENT_LIST:
      return {
        ...state,
        hipozStudentList: payload
      };
    case GET_TALENTPOOL_STUDENT_LIST:
      return {
        ...state,
        talentpoolStudentList: payload
      };
    case GET_STUDENT_JOB_APPLY_LIST:
      return {
        ...state,
        studentJobApplyList: payload
      };
    case GET_COMPANY_LIST:
      return {
        ...state,
        companyList: payload
      };
    case GET_COMPANY_JOB_LIST:
      return {
        ...state,
        companyJobList: payload
      };
    case GET_SUBSCRIPTIONS_PLANS:
      return {
        ...state,
        subscriptionsPlans: payload
      };
    case GET_SUBSCRIPTION_INFO:
      return {
        ...state,
        subscriptionInfo: payload
      };
    case GET_SUBSCRIBERS_LIST:
      return {
        ...state,
        subscribersList: payload
      };
    case GET_PARTNER_LIST:
      return {
        ...state,
        partnerList: payload
      };
    case GET_PARTNER_EVENTS_LIST:
      return {
        ...state,
        partnerEventsList: payload
      };
    case GET_PARTNER_BENEFITS_LIST:
      return {
        ...state,
        partnerBenefitsList: payload
      };
    case GET_STUDENT_SIGNUP_CONTENT:
      return {
        ...state,
        studentSignupContent: payload
      };
    case GET_COMPANY_SIGNUP_CONTENT:
      return {
        ...state,
        companySignupContent: payload
      };
    case GET_STUDENT_BANNER_LIST:
      return {
        ...state,
        studentBannerList: payload
      };
    case GET_COMPANY_BANNER_LIST:
      return {
        ...state,
        companyBannerList: payload
      };
    case GET_EVENT_BANNER_LIST:
      return {
        ...state,
        eventBannerList: payload
      };
    case GET_BENEFIT_BANNER_LIST:
      return {
        ...state,
        benefitBannerList: payload
      };
    case GET_BENEFIT_OFFER_LIST:
      return {
        ...state,
        benefitOfferList: payload
      };
    case GET_EVENT_OFFER_LIST:
      return {
        ...state,
        eventOfferList: payload
      };
    case GET_NEWS_FEED_LIST:
      return {
        ...state,
        newsFeedList: payload
      };
    case GET_ADMIN_PROFILE:
      return {
        ...state,
        adminProfile: payload
      };
    case GET_STUDENT_SUBSCRIBERS_LIST:
      return {
        ...state,
        studentSubscribersList: payload
      };
    case GET_COMPANY_SUBSCRIBERS_LIST:
      return {
        ...state,
        companySubscribersList: payload
      };
    case GET_PARTNER_SUBSCRIBERS_LIST:
      return {
        ...state,
        partnerSubscribersList: payload
      };
    case GET_ALL_SUBSCRIBERS_LIST:
      return {
        ...state,
        allSubscribersList: payload
      };
    case GET_ADMIN_DASHBOARD_COUNT:
      return {
        ...state,
        adminDashboardCount: payload
      };
    case GET_TERMS_CONTENT:
      return {
        ...state,
        terms: payload
      };
    default:
      return state;
  }
};
