/**
 * @author: Ashay Tiwari
 * @date: 22 July 2021
 * @description: File for passing reducer function of company profile actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  COMPANY_BRANCHES,
  COMPANY_JOBS_LIST,
  COMPANY_LANGUAGE,
  COMPANY_PROFILE
} from "../constants/action-types/companyProfile.actionTypes";

const initialState = {
  basicProfile: [],
  companyLanguage: [],
  companyBranch: [],
  companyjobsList: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_PROFILE:
      return {
        ...state,
        basicProfile: payload
      };
    case COMPANY_LANGUAGE:
      return {
        ...state,
        companyLanguage: payload
      };
    case COMPANY_BRANCHES:
      return {
        ...state,
        companyBranch: payload
      };
    case COMPANY_JOBS_LIST:
      return {
        ...state,
        companyjobsList: payload
      };
    default:
      return state;
  }
};
