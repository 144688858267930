/**
 * @author: Ashay Tiwari
 * @date: 11 July 2021
 * @description: File for Student Signup Page 4 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const STUDENT_CV_FILE = "STUDENT_CV_FILE";
export const STUDENT_JOB_TYPE = "STUDENT_JOB_TYPE";
export const STUDENT_LANGUAGES = "STUDENT_LANGUAGES";
export const STUDENT_LEVEL = "STUDENT_LEVEL";
export const STUDENT_SPECIALIZATION = "STUDENT_SPECIALIZATION";
export const STUDENT_INTERESTED = "STUDENT_INTERESTED";
export const STUDENT_INDUSTRY_FIELD = "STUDENT_INDUSTRY_FIELD";
export const STUDENT_COMPANY_LOCATION = "STUDENT_COMPANY_LOCATION";
export const STUDENT_WORK_ABROAD = "STUDENT_WORK_ABROAD";
export const STUDENT_SALARY_EXPECTATION = "STUDENT_SALARY_EXPECTATION";
