/**
 * @author: Ashay Tiwari
 * @date: 5 Aug 2021
 * @description: File for passing reducer function of common actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  NEWS_FEED,
  SEARCH_ENGINE_RESULT
} from "../constants/action-types/common.actionTypes";

const initialState = {
  newsFeed: [],
  searchResult: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NEWS_FEED:
      return {
        ...state,
        newsFeed: payload
      };
    case SEARCH_ENGINE_RESULT:
      return {
        ...state,
        searchResult: payload
      };
    default:
      return state;
  }
};
