/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for passing reducer function of company signup page 1
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  COMPANY_CONFIRM_PASSWORD,
  COMPANY_EMAIL_ADDRESS,
  COMPANY_FIRST_NAME,
  COMPANY_GENDER,
  COMPANY_LAST_NAME,
  COMPANY_NAME,
  COMPANY_PASSWORD,
  COMPANY_PHONE_CODE,
  COMPANY_PHONE_NUMBER,
  COMPANY_POSITION,
  COMPANY_TERMS_AND_CONDITIONS
} from "../../constants/action-types/CompanySignup/companySignup1.actionTypes";

const initialState = {
  companyFirstName: "",
  companyLastName: "",
  companyGender: "",
  companyName: "",
  companyPosition: "",
  companyEmailAddress: "",
  companyPhoneNumber: "",
  companyPhoneCode: "",
  companyPassword: "",
  companyConfirmPassword: "",
  companyTermsAndCondition: true
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COMPANY_FIRST_NAME:
      return {
        ...state,
        companyFirstName: payload
      };
    case COMPANY_LAST_NAME:
      return {
        ...state,
        companyLastName: payload
      };
    case COMPANY_GENDER:
      return {
        ...state,
        companyGender: payload
      };
    case COMPANY_NAME:
      return {
        ...state,
        companyName: payload
      };
    case COMPANY_POSITION:
      return {
        ...state,
        companyPosition: payload
      };
    case COMPANY_EMAIL_ADDRESS:
      return {
        ...state,
        companyEmailAddress: payload
      };
    case COMPANY_PHONE_NUMBER:
      return {
        ...state,
        companyPhoneNumber: payload
      };
    case COMPANY_PASSWORD:
      return {
        ...state,
        companyPassword: payload
      };
    case COMPANY_CONFIRM_PASSWORD:
      return {
        ...state,
        companyConfirmPassword: payload
      };
    case COMPANY_PHONE_CODE:
      return {
        ...state,
        companyPhoneCode: payload
      };
    case COMPANY_TERMS_AND_CONDITIONS:
      return {
        ...state,
        companyTermsAndCondition: payload
      };
    default:
      return state;
  }
};
