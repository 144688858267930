/**
 * @author: Ashay Tiwari
 * @date: 2  Sep 2021
 * @description: File for passing reducer function of admin graph actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  GET_JOBS_GRAPHS,
  GET_PAYMENT_GRAPHS,
  GET_REGISTRATION_GRAPHS
} from "../constants/action-types/adminGraph.actionTypes";

const initialState = {
  registrationGraph: [],
  paymentGraph: [],
  jobsGraph: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REGISTRATION_GRAPHS:
      return {
        ...state,
        registrationGraph: payload
      };
    case GET_PAYMENT_GRAPHS:
      return {
        ...state,
        paymentGraph: payload
      };
    case GET_JOBS_GRAPHS:
      return {
        ...state,
        jobsGraph: payload
      };
    default:
      return state;
  }
};
