/**
 * @author: Ashay Tiwari
 * @date: 22 July 2021
 * @description: File for company profile Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const COMPANY_PROFILE = "COMPANY_PROFILE";
export const COMPANY_LANGUAGE = "COMPANY_LANGUAGE";
export const COMPANY_BRANCHES = "COMPANY_BRANCHES";
export const COMPANY_JOBS_LIST = "COMPANY_JOBS_LIST";
