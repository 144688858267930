/**
 * @author: Ashay Tiwari
 * @date: 10 July 2021
 * @description: File for Student Signup Page 3 Action Types
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */
export const STUDENT_SCHOLARSHIP = "STUDENT_SCHOLARSHIP";
export const STUDENT_SCHOLARSHIP_TYPE = "STUDENT_SCHOLARSHIP_TYPE";
export const STUDENT_SCHOLARSHIP_NAME = "STUDENT_SCHOLARSHIP_NAME";
export const STUDENT_WORK_AVAILABILTY = "STUDENT_WORK_AVAILABILTY";
export const STUDENT_GRADUATED_HISTORY = "STUDENT_GRADUATED_HISTORY";
