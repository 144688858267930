/**
 * @author: Ashay Tiwari
 * @date: 2 Aug 2021
 * @description: File for passing reducer function of student dashboard actions
 * @developedBy: Kritin Digital Solutions Pvt. Ltd.
 */

import {
  STUDENT_COMPANY_FOLLOWING_LIST,
  STUDENT_COMPANY_LIST,
  STUDENT_RECOMMENDED_JOBS,
  STUDENT_APPLIED_JOBS_LIST
} from "../constants/action-types/studentDashboard.actionTypes";

const initialState = {
  companyList: [],
  companyFollowingList: [],
  recommendedJobsList: [],
  appliedJobsList: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case STUDENT_COMPANY_LIST:
      return {
        ...state,
        companyList: payload
      };
    case STUDENT_COMPANY_FOLLOWING_LIST:
      return {
        ...state,
        companyFollowingList: payload
      };
    case STUDENT_RECOMMENDED_JOBS:
      return {
        ...state,
        recommendedJobsList: payload
      };
    case STUDENT_APPLIED_JOBS_LIST:
      return {
        ...state,
        appliedJobsList: payload
      };
    default:
      return state;
  }
};
